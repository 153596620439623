<template>
  <section>
    <v-row no-gutters>
      <v-col cols="12" class="d-flex flex-column my-6">
        <span class="secondary--text text-h6">Agregar Insumo</span>
        <span> {{ objetoPresupuestario?.codigo }} {{ objetoPresupuestario?.nombre }} </span>
        <span
          v-if="objetoPresupuestario?.nombre_obs_clase"
          v-text="'Clase: ' + objetoPresupuestario?.nombre_obs_clase"
        />
        <span
          v-if="objetoPresupuestario?.nombre_obs_familia"
          v-text="'Familia: ' + objetoPresupuestario?.nombre_obs_familia"
        />
        <span
          v-if="objetoPresupuestario?.nombre_obs_segmento"
          v-text="'Segmento: ' + objetoPresupuestario?.nombre_obs_segmento"
        />
      </v-col>
    </v-row>
    
    <v-spacer />
    <!-- header de table -->
   
    <!-- promedio de precios
    <v-col cols="12" class="mt-6">
      <div class="d-flex text-center flex-column secondary--text">
        <span class="mb-1">Precio promedio de mercado</span>
        <span class="text-h5 my-1">
          {{
            Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(precioPromedio)
          }}
        </span>
      </div>
      <div class="mt-1 d-flex">
        <v-spacer />
        <v-spacer v-if="br.mdAndUp" />
        <v-chip outlined color="secondary">
          <span class="text-h6">
            {{
              Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(minPrecio)
            }}
          </span>
        </v-chip>
        <v-divider class="my-auto" color="secondary" />
        <v-chip outlined color="secondary">
          <span class="text-h6">
            {{
              Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(maxPrecio)
            }}
          </span>
        </v-chip>
        <v-spacer v-if="br.mdAndUp" />
        <v-spacer />
      </div>
    </v-col> -->
  
  </section>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";
import { helpers, required } from "vuelidate/lib/validators";
import moment from "moment";

// const txtField = helpers.regex("txtArea", /^[A-Za-zñÑáéíóúÁÉÍÓÚ0-9 ]*$/);


export default {
  name: "PreciosMercado",
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    unidadMedida: null,
    formating: true,
    aclaraciones_observaciones: null,
    precio_unitario: null,
    cantidad: null
  }),
  computed: {
    ...mapState("agregarInsumo", [
      "objetoPresupuestario",
      "ctl_unidades_medida",
      "nuevoInsumo",
      "insumoEditable",
    ]),
    formatCurrency() {
      if (
        this.formating &&
        this.$v.infoArchivo.precioUnitario.$model &&
        !this.$v.infoArchivo.precioUnitario.$invalid
      ) {
        return Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(this.infoArchivo.precioUnitario);
      } else {
        return this.infoArchivo.precioUnitario;
      }
    },
    br() {
      return this.$vuetify.breakpoint;
    },
    precioPromedio() {
      if (this.nuevoInsumo.cotizaciones.length > 0) {
        let values = this.createArrayFromPrecios();
        let sum = values.reduce((previous, current) => (current += previous));
        let avg = sum / values.length;
        return avg;
      } else {
        return 0;
      }
    },
    minPrecio() {
      if (this.nuevoInsumo.cotizaciones.length > 0) {
        let values = this.createArrayFromPrecios();
        let min = Math.min(...values);
        return min;
      } else {
        return 0;
      }
    },
    maxPrecio() {
      if (this.nuevoInsumo.cotizaciones.length > 0) {
        let values = this.createArrayFromPrecios();
        let max = Math.max(...values);
        return max;
      } else {
        return 0;
      }
    },
  },
  methods: {
    moment,
    ...mapMutations("agregarInsumo", [
      "addPrecioMercado",
      "deletePrecioMercado",
      "setIdUnidadMedida",
      "cleanUnidadMedida",
      "cleanCotizaciones",
      "cleanUnidadesMedida",
      "setPrecioUnitarioGlobal",
    ]),
    ...mapActions("agregarInsumo", [
      "getUnidadesMedida",
      "getObjetosPresupuestarios",
    ]),
    deleteAction(id) {
      if (this.insumoEditable) {
        this.modalEliminarCotizacion = true;
        this.idCotizacion = id;
      } else {
        this.deletePrecioMercado(id);
      }
    },
    clearInfoArchivo() {
      this.infoArchivo.nombreArchivo = null;
      this.infoArchivo.precioUnitario = null;
      this.infoArchivo.archivo = null;
      this.$refs.montoTxt.clear();
      this.$v.$reset();
    },
    async addInsumo() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      } else {
        if (this.insumoEditable) {
          let formData = new FormData();
          formData.append("nombre_archivo", this.infoArchivo.nombreArchivo);
          formData.append("precio_unitario", this.infoArchivo.precioUnitario);
          formData.append("documento", this.infoArchivo.archivo);
          const response =
            await this.services.SolicitudCompra.addSolicitudCotizacionDetalle(
              this.nuevoInsumo.id,
              formData
            );
          if (response.status === 201) {
            this.temporalAlert({
              show: true,
              message: "Cotización agregada con éxito",
              type: "success",
            });
            this.addPrecioMercado({
              documento_ruta: response.data.documento_ruta,
              fecha_carga: response.data.created_at,
              id: response.data.id,
              nombre_documento: response.data.nombre_documento,
              precio_unitario: response.data.monto,
            });
            this.clearInfoArchivo();
            this.modalAddInsumo = false;
          }
        } else {
          this.addPrecioMercado({
            id: this.nuevoInsumo.cotizaciones.length + 1,
            nombre_documento: this.infoArchivo.nombreArchivo,
            precio_unitario: this.infoArchivo.precioUnitario,
            archivo: this.infoArchivo.archivo,
            fecha_carga: this.moment(),
          });
          this.clearInfoArchivo();
          this.modalAddInsumo = false;
        }
      }
    },
    createArrayFromPrecios() {
      let precios = [];
      this.nuevoInsumo.cotizaciones.forEach((insumo) => {
        precios.push(Number(insumo.precio_unitario));
      });
      return precios;
    },
    async deleteCotizacion() {
      const { status } =
        await this.services.SolicitudCompra.deleteSolicitudCotizacionDetalle(
          this.idCotizacion
        );

      if (status == 204) {
        this.temporalAlert({
          show: true,
          message: "Detalle de cotización eliminado con éxito",
          type: "success",
        });
        this.deletePrecioMercado(this.idCotizacion);
        this.modalEliminarCotizacion = false;
      }
    },
    async downloadFile(route) {
      try {
        const response = await this.services.SolicitudCompra.getUrlDocumento({
          documento: route,
        });
        if (response) {
          const blob = new Blob([response.data], {
            type: "application/pdf",
          });

          const file = new File([blob], "documento.pdf", {
            type: "application/pdf",
          });

          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(file);
          link.download = route.toString();
          link.click();
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  watch: {
    unidadMedida(value) {
      if (value) {
        this.setIdUnidadMedida(value);
      } else {
        this.cleanUnidadMedida();
      }
    },
    maxPrecio(value) {
      if (value) {
        this.setPrecioUnitarioGlobal(value);
      } else {
        this.setPrecioUnitarioGlobal(null);
      }
    },
    "nuevoInsumo.id"() {
      this.unidadMedida = {
        id: this.nuevoInsumo.id_unidad_medida,
        nombre: this.nuevoInsumo.unidad_medida,
      };
    },
  },
  created() {
    this.getUnidadesMedida();
    if (this.nuevoInsumo.id_unidad_medida) {
      this.unidadMedida = {
        id: this.nuevoInsumo.id_unidad_medida,
        nombre: this.nuevoInsumo.unidad_medida,
      };
    } else {
      this.unidadMedida = null;
    }
    // // logica de verificacion de cotizaciones
    // if (this.insumoEditable) {
    //   if (this.nuevoInsumo.id == null) {
    //     this.$router.push({
    //       name: "compras",
    //       params: { tipo: Number(this.$route.params.tipo),id_solicitud: this.id_solicitud, },
    //     });
    //   }
    // } else if (this.objetoPresupuestario.id == null) {
    //   this.$router.push({
    //     name: "compras",
    //     params: { tipo: Number(this.$route.params.tipo),id_solicitud: this.id_solicitud, },
    //   });
    // }
  },
  beforeDestroy() {
    this.cleanUnidadMedida();
    this.setPrecioUnitarioGlobal(null);
  },
};
</script>

<style lang="scss" scoped>
#prom-container {
  max-width: 100px;
}
</style>
