<template>
  <section>
    <v-layout>
      <v-flex>
        <v-form>
          <v-row>
            <p class="text-h6 mb-5">Agregar Insumo</p>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <v-autocomplete
                :items="items1"
                item-text="name"
                item-value="id"
                v-model="form.estructuraPresupuestaria"
                label="Estructura presupuestaria"
                outlined
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <v-autocomplete
                :items="items2"
                item-text="name"
                item-value="id"
                v-model="form.organismoFinanciero"
                label="Organismo financiero"
                outlined
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="2" md="2">
              <v-autocomplete
                :items="items3"
                item-text="name"
                item-value="id"
                v-model="form.tipoOBS"
                label="Tipo OBS"
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-autocomplete
                :items="items4"
                item-text="name"
                item-value="id"
                v-model="form.objPresupuestario"
                label="Objeto presupuestario"
                return-object
                outlined
              >
                <template #[`item`]="{ item }">
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          item.id + " " + item.name
                        }}</v-list-item-title>
                        <v-list-item-subtitle>
                          Clase: {{ item.clase }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          Marcancia:
                          {{ item.marcancia }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-row v-if="insumoSeleccionado">
            <v-card color="bgMinsal" elevation="0">
              <v-card-title>{{ insumoSeleccionado.name }}</v-card-title>
              <v-card-text>
                <p>Marcancia: {{ insumoSeleccionado.marcancia }}</p>
                <p>Clase: {{ insumoSeleccionado.clase }}</p>
              </v-card-text>
            </v-card>
          </v-row>

          <v-row>
            <v-btn
              color="secondary"
              class="white--text"
              :disabled="insumoSeleccionado ? false : true"
              @click="redirecTo()"
              small
              >Continuar</v-btn
            >
          </v-row>
        </v-form>
      </v-flex>
    </v-layout>
  </section>
</template>
<script>
import { mapMutations, mapState } from "vuex";
export default {
  name: "AgregarInsumosComponent",
  data: () => ({
    tipo: 0,
    form: {
      estructuraPresupuestaria: null,
      organismoFinanciero: null,
      tipoOBS: null,
      objPresupuestario: null,
    },

    items1: [
      { id: 1, name: "Direccion y Administracion Institucional" },
      { id: 2, name: "Otro" },
    ],
    items2: [
      { id: 1, name: "Fondo General (Recursos de caracter ordinario)" },
      { id: 2, name: "Otro" },
    ],
    items2: [
      { id: 1, name: "Fondo General (Recursos de caracter ordinario)" },
      { id: 2, name: "Otro" },
    ],
    items3: [
      { id: 1, name: "Bienes" },
      { id: 2, name: "Obras" },
      { id: 3, name: "Servicios" },
    ],
    items4: [
      {
        id: 14111500,
        name: "Papel de imprenta y papel de escribir",
        clase: "14111500 Papel de imprenta y de escribir",
        marcancia: "14111501 Papel de escritura",
      },
    ],
  }),
  methods: {
    ...mapMutations("agregarInsumo", ["setInsumo"]),
    /**
     * @description : redirecciona a vista y setea vuex con objeto
     */
    redirecTo() {
      this.$router.push({
        name: "agregar-insumo-detalles",
        params: { tipo: Number(this.tipo), idSolicitud: this.id_solicitud },
      });
      this.setInsumo(this.form);
    },
  },
  computed: {
    ...mapState("agregarInsumo", [
      "id_solicitud",
    ]),
    /**
     * @description : setea tarjeta para mostra insumo seleccionado
     */
    insumoSeleccionado() {
      return this.form.objPresupuestario;
    },
  },
  created() {
    this.tipo = Number(this.$route.params.tipo);
  },
};
</script>
