<template>
  <section>
    <v-row>
      <v-col cols="12" sm="6">
        <v-autocomplete
          :items="cifrados_presupuestarios"
          item-text="cifrado_presupuestario"
          item-value="id"
          label="Cifrado presupuestario *"
          outlined
          v-model="nuevoInsumo.id_financiamiento_unidad"
          clearable
          :error-messages="cifradosErrors"
          @blur="$v.nuevoInsumo.id_financiamiento_unidad.$touch()"
        />
      </v-col>
    </v-row>
  </section>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import ObsPreviewComponent from "@/components/ObsPreviewComponent.vue";
import { required } from "vuelidate/lib/validators";

export default {
  name: "AgregarInsumoMarcoComponent",
  validations: {
    nuevoInsumo: {
      id_financiamiento_unidad: {
        required,
      },
    },
  },
  data: () => ({
    cifrados_presupuestarios: [],
  }),
  components: {
    ObsPreviewComponent,
  },
  computed: {
    ...mapState("catalogo", [
      "productoSeleccionado",
      "id_producto_seleccionado",
    ]),
    ...mapState("agregarInsumo", ["nuevoInsumo", "insumoEditable"]),
    ...mapState(["selectedUnidad"]),
    cifradosErrors() {
      const errors = [];
      if (!this.$v.nuevoInsumo.id_financiamiento_unidad.$dirty) return errors;
      !this.$v.nuevoInsumo.id_financiamiento_unidad.required &&
        errors.push("Debe seleccionar un cifrado presupuestario");
      return errors;
    },
  },
  methods: {
    ...mapActions("catalogo", ["obtenerProducto"]),
    ...mapMutations("agregarInsumo", ["setObjetoPresupuestario"]),

    async setFormInfo() {
      if (this.$route.params.tipo == 2) {
        this.nuevoInsumo.id_obs =
          this.productoSeleccionado?.obs_proveedor?.obs_id;
        this.nuevoInsumo.precio_unitario = Number(
          this.productoSeleccionado?.precio_unitario
        );
        this.nuevoInsumo.id_presentacion_producto_proveedor =
          this.productoSeleccionado?.id;
      }

      if (!this.insumoEditable && this.$route.params.tipo == 2) {
        await this.obtenerProducto(this.id_producto_seleccionado);
      }
    },
    async getCifradosPresupuestarios() {
      const idUnidad =
        this.selectedUnidad.cargo[0]?.id == 11
          ? this.nuevoInsumo.id_unidad
          : this.selectedUnidad.id;
      const { data, status } = await this.services.Cifrados.getCifrados({
        id_unidad: idUnidad,
      });
      if (status == 200) {
        this.cifrados_presupuestarios = data;
      }
    },
  },
  watch: {
    "nuevoInsumo.id_unidad"() {
      // this.setObjetoPresupuestario()

      this.getCifradosPresupuestarios();
    },
  },
  async created() {
    if (this.selectedUnidad.cargo[0]?.id !== 11)
      this.getCifradosPresupuestarios();
     this.setFormInfo();
  },
};
</script>
<style scoped>
.v-text-field.v-input--is-disabled
  .v-messages[role="alert"]
  .v-messages__message {
  color: red !important;
}
</style>
