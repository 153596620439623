<template>
  <section>
    <div v-if="showActionsInsumo" class="text-end mb-6">
      <v-btn
        :disabled="validacionUACI"
        @click="modalAprobarInsumo = true"
        class="px-0"
        text
        color="green"
      >
        <v-icon color="green" small class="mr-2">mdi-check</v-icon>
        Insumo listo
      </v-btn>
      <v-btn
        @click="modalCancelarInsumo = true"
        class="px-0 mx-5"
        text
        color="red"
      >
        <v-icon small class="mr-2" color="red">mdi-cancel</v-icon>
        Descartar insumo
      </v-btn>
      <v-btn
        @click="modalObservacionInsumo = true"
        class="px-0 mx-5"
        text
        color="primary"
      >
        <v-icon small class="mr-2" color="primary"
          >mdi-message-reply-text</v-icon
        >
        Marcar con observación
      </v-btn>
    </div>
    <v-row class="mx-3 mb-5">
      <titulo />
    </v-row>

    <!-- filtro de objeto presupuestario en opcion editar -->
    <template v-if="verifyEdit">
      <agregar-obj-prspt-component ref="formFilterEP" />
    </template>

    <!--Dependiendo del tipo de compra se mostrara un detalle diferente de insumos -->
    <template v-if="$route.params.tipo == 1">
      <agregar-insumo-proceso-compra
        :editable="insumoEditable || handleEstado"
      />
    </template>
    <template v-if="$route.params.tipo == 2">
      <AgregarInsumoConvenioMarco />
    </template>
    <template v-if="$route.params.tipo == 3">
      <AgregarInsumoCompraLinea
        ref="lineaForm"
        @cambioPrecioUnitario="actualizarPrecioUnitarioInsumos"
        :unidadMedida="unidadMedida"
        :precioUnitario="precio_unitario"
      />
    </template>

    <!--Documentos de productos (Solo UACI) --->
    <v-row v-if="handleUACI">
      <v-alert
        v-if="insumosDocs.length == 0"
        color="grayMinsal"
        border="left"
        colored-border
        icon="mdi-information-outline"
        dense
      >
        Para poder marcar el insumo como listo es necesario cargar al menos un
        documento
      </v-alert>
      <DocumentosCargadosComponent
        @saveDoc="saveDocument"
        @reloadDoc="getDocumentosInsumo()"
        :headers="headersDocs"
        :items="insumosDocs"
      />
    </v-row>
    <!-- Especificaciones -->
    <v-row>
      <v-col cols="12">
        <v-textarea
          v-model="aclaraciones_observaciones"
          label="Especificación *"
          outlined
          :error-messages="aclarionesErrors"
          @blur="$v.aclaraciones_observaciones.$touch()"
          maxlength="250"
          counter="250"
          auto-grow
          row-height="30"
          rows="4"
        />
      </v-col>
    </v-row>
    <!-- $route.params.tipo == 1 && !flagEdicionArchivo -->
    <v-row v-if="$route.params.tipo == 1">
      <v-col cols="12" sm="6" md="4" class="pb-0">
        <v-file-input
          accept=".pdf"
          label="Archivo de especificaciones"
          prepend-icon="mdi-file-pdf-box"
          multiple
          clearable
          v-model="archivoEspecificaciones"
          @change="inputFileChange"
          :error-messages="archivoEspecificacionesError"
        >
          <template v-slot:selection="{ text, index, file }">
            <v-chip
              small
              text-color="white"
              color="#295671"
              close
              @click:close="removeFromArchivosEspecificaciones(index)"
            >
              {{ text }}
            </v-chip>
          </template>
        </v-file-input>
        <div v-if="files.length">
          <h5>Archivos</h5>
          <v-chip v-for="f in files" v-bind:key="f.name" class="mr-1">
            {{ f.name }}
          </v-chip>
        </div>
      </v-col>
    </v-row>
    <v-row
      v-if="
        $route.params.tipo == 1 && archivoEspecificacionesPrecargado != null
      "
      class="d-flex flex-column mb-2"
    >
      <v-col
        cols="12"
        sm="6"
        md="4"
        v-for="(archivo, index) in archivoEspecificacionesPrecargado"
        :key="index"
        class="py-2"
      >
        <v-icon v-text="'mdi-file-pdf-box'" />
        <span class="px-3" v-text="decodeString(archivo.nombre_documento)" />
        <v-btn icon>
          <v-icon
            v-text="'mdi-close'"
            @click="eliminarArchivoEspecificacion(archivo.id)"
          />
        </v-btn>
        <v-btn
          icon
          @click="
            downloadArchivoEspecificacion(
              archivo.url_documento,
              archivo.nombre_documento
            )
          "
        >
          <v-icon v-text="'mdi-download'" />
        </v-btn>
      </v-col>
    </v-row>
    <!-- selector de unidad de medida -->
    <v-row v-if="$route.params.tipo == 1 || $route.params.tipo == 3">
      <v-col cols="12" sm="6" md="4">
        <v-autocomplete
          v-model="unidadMedida"
          item-text="nombre"
          return-object
          :items="ctl_unidades_medida"
          no-data-text="Sin unidades de medida"
          :rules="[(v) => !!v || 'Seleccione una unidad de medida']"
          label="Unidad de medida *"
          outlined
          clearable
        />
      </v-col>
    </v-row>
    <!-- precio unitario -->
    <v-row>
      <v-col cols="12" sm="4" md="3">
        <v-text-field
          :readonly="$route.params.tipo == 2"
          v-model="precio_unitario"
          label="Precio unitario *"
          outlined
          :clearable="$route.params.tipo != 2"
          :error-messages="precioErrors"
          @blur="$v.precio_unitario.$touch()"
        />
      </v-col>
    </v-row>
    <!-- cantidad a solicitar -->
    <v-row>
      <v-col cols="12" sm="4" md="3">
        <v-text-field
          v-model="cantidad"
          label="Cantidad a solicitar *"
          clearable
          outlined
          v-mask="'#######'"
          maxlength="7"
          :rules="[
            (v) => v > 0 || 'Debe de ingresar la cantidad un número mayor a 0',
          ]"
          :error-messages="cantidadErrors"
          @blur="$v.cantidad.$touch()"
        />
      </v-col>
    </v-row>
    <v-row class="my-6 pb-6">
      <v-btn
        @click="regresar()"
        class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4"
        >Volver</v-btn
      >
      <template v-if="handlePasosSolicitudes">
        <v-btn
          v-if="handleReadOnlyUACI && handleEstado"
          class="white--text mx-3 text-no-style secondary px-8"
          @click="insumoEditable ? editarInsumo() : agregarInsumo()"
        >
          {{ insumoEditable ? "Guardar cambios y volver" : "Agregar insumo" }}
        </v-btn>
      </template>
    </v-row>
    <ConfirmationDialog
      :show="modalEliminarInsumo"
      title="¿Deseas remover el insumo?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Aceptar"
      @close="modalEliminarInsumo = false"
      @confirm="eliminarInsumo()"
    />
    <ConfirmationDialog
      :show="modalAprobarInsumo"
      title="¿Deseas aprobar el insumo?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Aceptar"
      @close="modalAprobarInsumo = false"
      @confirm="changeStatusInsumo('aprobar')"
    />
    <ConfirmationDialog
      :show="modalCancelarInsumo"
      title="¿Deseas descartar el insumo?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Continuar"
      @close="modalCancelarInsumo = false"
      @confirm="changeStatusInsumo('descartar')"
    />
    <ConfirmationDialog
      :show="modalObservacionInsumo"
      title="Marcar con observación"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Continuar"
      showMotivoCancelar
      @input="setMotivo"
      @close="modalObservacionInsumo = false"
      @confirm="changeStatusInsumo('observar')"
    />
    <ChatComponent :idSolicitud="id_solicitud" modelo="SolicitudCompra" />
  </section>
</template>
<script>
import {
  requiredIf,
  required,
  maxLength,
  helpers,
  decimal,
  numeric,
} from "vuelidate/lib/validators";

import { mapMutations, mapState, mapActions } from "vuex";
import ConfirmationDialog from "@/components/ConfirmationDialogComponent.vue";
import ObsPreviewComponent from "@/components/ObsPreviewComponent.vue";
import ChatComponent from "@/components/ChatComponent";
import AgregarInsumoConvenioMarco from "./components/AgregarInsumoConvenioMarcoComponent.vue";
import AgregarInsumoCompraLinea from "./components/AgregarInsumoCompraLineaComponent.vue";
import AgregarInsumoProcesoCompra from "./components/AgregarInsumoProcesoCompraComponent.vue";
import titulo from "./components/TituloVistaComponent.vue";
import DocumentosCargadosComponent from "@/components/DocumentosCargadosComponent.vue";
import AgregarObjPrsptComponent from "./components/AgregarObjPrsptComponent.vue";
import MoneyTextFieldComponent from "../../components/MoneyTextFieldComponent.vue";

const precio = (value) => {
  return value > 0;
};

const priceDecimals = helpers.regex(
  "priceDecimals",
  /^[0-9]+([.][0-9]{1,2})?$/
);

export default {
  name: "AgregarInsumoDetalleView",
  components: {
    ConfirmationDialog,
    ObsPreviewComponent,
    ChatComponent,
    AgregarInsumoConvenioMarco,
    AgregarInsumoCompraLinea,
    AgregarInsumoProcesoCompra,
    titulo,
    DocumentosCargadosComponent,
    AgregarObjPrsptComponent,
    MoneyTextFieldComponent,
  },
  validations: {
    aclaraciones_observaciones: {
      required,
      maxLength: maxLength(250),
    },
    precio_unitario: {
      required,
      precio,
      priceDecimals,
      decimal,
    },
    cantidad: {
      required,
      numeric,
    },
    unidadMedida: {
      required: requiredIf(function () {
        this.$route.params.tipo == 2 ? false : true;
      }),
    },
  },

  data: () => ({
    items: [],
    item: null,
    idMes: null,
    unidadMedida: null,
    nombre_unidad_medida: null,
    cantidad: null,
    aclaraciones_observaciones: null,
    archivoEspecificaciones: null,
    flagEdicionArchivo: false,
    archivoEspecificacionesPrecargado: null,
    idInsumoEditando: null,
    meses: [],
    valores: [
      { id: 1, name: "Enero" },
      { id: 2, name: "Febrero" },
      { id: 3, name: "Marzo" },
      { id: 4, name: "Abril" },
      { id: 5, name: "Mayo" },
      { id: 6, name: "Junio" },
      { id: 7, name: "Julio" },
      { id: 8, name: "Agosto" },
      { id: 9, name: "Septiembre" },
      { id: 10, name: "Octubre" },
      { id: 11, name: "Noviembre" },
      { id: 12, name: "Diciembre" },
    ],
    headers: [
      {
        text: "Correlativo",
        align: "center",
        value: "correlativo",
        width: "10%",
        sortable: false,
      },
      {
        text: "Mes",
        align: "center",
        value: "mes",
        width: "10%",
        sortable: false,
      },
      {
        text: "Cantidad",
        align: "center",
        value: "cantidad",
        width: "10%",
        sortable: false,
      },
      {
        text: "Precio unitario ($)",
        align: "center",
        value: "precio_unitario",
        width: "10%",
        sortable: false,
      },
      {
        text: "Gastos Adicionales",
        align: "center",
        value: "gastos_adicionales",
        width: "10%",
        sortable: false,
      },
      {
        text: "Total ($)",
        align: "center",
        value: "total",
        width: "5%",
        sortable: false,
      },
      {
        text: "Acciones",
        align: "center",
        value: "acciones",
        width: "10%",
        sortable: false,
      },
    ],
    headersReadOnly: [
      {
        text: "Correlativo",
        align: "center",
        value: "correlativo",
        width: "10%",
        sortable: false,
      },
      {
        text: "Mes",
        align: "center",
        value: "mes",
        width: "10%",
        sortable: false,
      },
      {
        text: "Cantidad",
        align: "center",
        value: "cantidad_r",
        width: "10%",
        sortable: false,
      },
      {
        text: "Precio unitario ($)",
        align: "center",
        value: "precio_unitario",
        width: "10%",
        sortable: false,
      },
      {
        text: "Gastos Adicionales",
        align: "center",
        value: "gastos_adicionales",
        width: "10%",
        sortable: false,
      },
      {
        text: "Total ($)",
        align: "center",
        value: "total",
        width: "5%",
        sortable: false,
      },
    ],
    headersDocs: [
      {
        text: "Documento",

        value: "nombre",
      },
      {
        text: "Fecha carga",
        align: "center",
        value: "fecha_carga",
      },

      {
        text: "Acciones",
        align: "center",
        value: "acciones_insumo",
        sortable: false,
      },
    ],
    modalInsumo: false,
    precio_unitario: null,
    modalEliminarInsumo: false,
    id_insumo: null,
    modalAprobarInsumo: false,
    modalCancelarInsumo: false,
    modalObservacionInsumo: false,
    motivoObservacion: "",
    insumosDocs: [],
    files: [],
  }),
  methods: {
    ...mapActions("catalogo", ["obtenerProducto"]),
    ...mapActions("agregarInsumo", ["guardarInsumo"]),
    ...mapMutations("agregarInsumo", [
      "setInsumoEditable",
      "clearFiltrosEstructuras",
      "setInsumoData",
      "setNuevoInsumoStep2",
      "setNuevoInsumoStep3",
      "setCantidadStep3",
      "setNuevoInsumoEdit",
      "setIdSolicitud",
      "setObjetoPresupuestario",
      "setPrecioUnitarioGlobal",
      "setIdUnidadMedida",
    ]),
    ...mapMutations("catalogo", ["cambiarProductoSeleccionado"]),
    decodeString(string) {
      return decodeURIComponent(escape(string));
    },
    validateFormatArchivosEspecificaciones() {
      let flag = false;
      if (
        this.archivoEspecificaciones != null &&
        this.archivoEspecificaciones.length > 0
      ) {
        // recorrer el arreglo de archivos
        for (let i = 0; i < this.archivoEspecificaciones.length; i++) {
          let extension = this.archivoEspecificaciones[i].name.split(".").pop();
          if (extension == "pdf") {
            flag = true;
          } else {
            flag = false;
            break;
          }
        }
      } else {
        flag = true;
      }
      return flag;
    },
    validateSizeArchivosEspecificaciones() {
      let flag = false;
      if (
        this.archivoEspecificaciones != null &&
        this.archivoEspecificaciones.length > 0
      ) {
        // recorrer el arreglo de archivos
        for (let i = 0; i < this.archivoEspecificaciones.length; i++) {
          let size = this.archivoEspecificaciones[i].size;
          if (size <= 512000) {
            flag = true;
          } else {
            flag = false;
            break;
          }
        }
      } else {
        flag = true;
      }
      return flag;
    },
    setData(atribute, value) {
      let params = {
        atributo: atribute,
        valor: value,
      };
      this.setNuevoInsumoEdit(params);
    },
    /**
     * @description : Agregar detalles de insumo
     */
    updateData(data) {
      this.items.forEach((item) => {
        if (item.id == data.id) {
          item.total = data.cantidad * data.precio_unitario;
        }
      });
    },

    updateCorrelativo() {
      // ordenar por id_mes
      this.items.sort((a, b) => {
        return a.id_mes - b.id_mes;
      });
      // asignar correlativo
      this.items.forEach((item, index) => {
        item.correlativo = index + 1;
      });
    },

    validateData() {
      const vs = this.nuevoInsumo;
      if (vs.id_obs == null || vs.id_financiamiento_unidad == null) {
        this.temporalAlert({
          show: true,
          type: "error",
          message: "Falta informacion de OBS",
        });
        return false;
      } else {
        if (vs.observacion == null) {
          this.temporalAlert({
            show: true,
            type: "error",
            message: "Debe ingresar una especificacion",
          });
          return false;
        } else {
          return true;
        }
      }
    },

    async agregarDetallesInsumo() {
      let response = null;

      if (
        this.cantidad === null ||
        this.cantidad === 0 ||
        this.cantidad < 1 ||
        this.meses.length === 0
      ) {
        this.temporalAlert({
          show: true,
          message: "Agregue una cantidad y seleccione por lo menos un mes",
          type: "warning",
        });
        return;
      }

      for (let i = 0; i < this.meses.length; i++) {
        const { name, id } = this.valores.find(
          (element) => element.id === this.meses[i]
        );

        let form = {};
        form.id = i + 1;
        // form.correlativo = i + 1;
        form.precio_unitario = this.precioUnitarioByType();
        form.gastos_adicionales = this.totalGastoAdicional();
        form.total = this.totalByType();
        form.mes = name;
        form.id_mes = id;
        form.cantidad = this.cantidad;

        if (
          !this.items.filter((item) => item.id_mes === this.meses[i]).length > 0
        ) {
          if (this.insumoEditable) {
            let data = {
              id_mes: form.id_mes,
              cantidad: Number(form.cantidad),
            };
            response =
              await this.services.SolicitudCompra.addSolicitudInsumoDetalle(
                this.nuevoInsumo.id,
                data
              );
          } else {
            // verificar que el id no se repita en el arreglo
            let id = form.id;
            let id_repetido = true;
            while (id_repetido) {
              if (this.items.filter((item) => item.id === id).length > 0) {
                id++;
              } else {
                id_repetido = false;
              }
            }
            form.id = id;

            this.items.push({ ...form });
          }
        }
      }

      if (response?.status == 201) {
        const only_table = true;
        this.reloadInsumo(only_table);
        if (!this.insumoEditable) this.reloadInsumo(only_table);
        this.temporalAlert({
          show: true,
          message: "Detalle de insumo agregado con éxito",
          type: "success",
        });
      }

      this.modalInsumo = false;
    },
    /**
     * @description : Eliminar registro de los detalle del insumo
     * @param {Number} id
     */
    async eliminarInsumo() {
      if (this.insumoEditable) {
        const { status } =
          await this.services.SolicitudCompra.deleteSolicitudInsumoDetalle(
            this.id_insumo
          );

        if (status == 200) {
          this.temporalAlert({
            show: true,
            message: "Detalle de insumo eliminado con éxito",
            type: "success",
          });
          this.reloadInsumo();
          if (!this.insumoEditable) this.reloadInsumo();
        }
      } else {
        this.items = this.items.filter((item) => item.id !== this.id_insumo);
        this.meses.forEach((element) => {
          if (element == this.idMes) {
            this.meses.splice(this.meses.indexOf(element), 1);
          }
        });
        this.updateCorrelativo();
      }
      this.modalEliminarInsumo = false;
    },
    /**
     * @description : Editar registro de los detalle del insumo
     * @param {Object} detalle
     */
    async editarDetalleInsumo(detalle) {
      const { status } =
        await this.services.SolicitudCompra.updateSolicitudInsumoDetalle(
          detalle.id,
          { cantidad: Number(detalle.cantidad) }
        );
      if (status == 200) {
        this.temporalAlert({
          show: true,
          message: "Detalle de insumo actualizado con éxito",
          type: "success",
        });
        if (!this.insumoEditable) this.reloadInsumo();
      }
    },
    /**
     * @description : Activa las validaciones según el componente que este
     * renderizado
     */
    activeTouchValidations() {
      switch (this.$route.params.tipo) {
        case 2:
          return this.$refs.formFilterEP.$v.$touch();
        case 3:
          return this.$refs.lineaForm.$v.$touch();
      }
    },
    /**
     * @description : Verifica si es valido el componente renderizado
     *
     */
    checkValidations() {
      switch (this.$route.params.tipo) {
        case 1:
          return false;
        case 2:
          return this.$refs.formFilterEP.$v.$invalid;
        case 3:
          return this.$refs.lineaForm.$v.$invalid;
      }
    },

    returnError() {
      this.activeTouchValidations();
      this.$v.$touch();
      this.temporalAlert({
        show: true,
        type: " error",
        message: " Verifique los campos obligatorios",
      });
    },

    /**
     * @description : Guardar datos del insumo a agregar
     */
    gatherFormData() {
      let solicitudesCompra = this.items.map((item) => {
        return {
          id_mes: item.id_mes,
          cantidad: Number(this.cantidad),
        };
      });
      let cotizacionesCompra = this.nuevoInsumo.cotizaciones.map((item) => {
        return {
          nombre: item.nombre_documento,
          precio_unitario: Number(item.precio_unitario),
        };
      });
      let data = new FormData();
      data.append(
        "id_financiamiento_unidad",
        JSON.stringify(this.nuevoInsumo?.id_financiamiento_unidad)
      );
      data.append("id_obs", JSON.stringify(this.objetoPresupuestario?.id));
      data.append(
        "id_unidad_medida",
        JSON.stringify(this.nuevoInsumo?.id_unidad_medida)
      );
      data.append("observacion", this.aclaraciones_observaciones);
      data.append(
        "precio_unitario",
        JSON.stringify(this.nuevoInsumo?.precio_unitario)
      );
      data.append("cantidad", Number(this.cantidad ?? 0));
      for (let i = 0; i < solicitudesCompra.length; i++) {
        data.append(
          "solicitud_compra_detalle_obs",
          JSON.stringify(solicitudesCompra[i])
        );
      }
      for (let i = 0; i < this.nuevoInsumo.cotizaciones.length; i++) {
        data.append("documentos", this.nuevoInsumo.cotizaciones[i].archivo);
      }
      for (let i = 0; i < cotizacionesCompra.length; i++) {
        data.append("cotizaciones", JSON.stringify(cotizacionesCompra[i]));
      }
      return data;
    },

    removeFromArchivosEspecificaciones(index) {
      this.archivoEspecificaciones.splice(index, 1);
      this.files.splice(index, 1);
    },

    async eliminarArchivoEspecificacion(id_archivo) {
      const response =
        await this.services.SolicitudCompra.deleteArchivoEspecificaciones(
          this.idInsumoEditando,
          id_archivo
        );

      if (response.status == 200) {
        this.temporalAlert({
          show: true,
          message: "Archivo eliminado con éxito",
          type: "success",
        });
        this.archivoEspecificacionesPrecargado =
          this.archivoEspecificacionesPrecargado.filter(
            (item) => item.id != id_archivo
          );
      }
    },

    async downloadArchivoEspecificacion(urlDocumento, nombreDocumento) {
      const response = await this.services.Paac.downloadDocumentoProcesoCompra(
        urlDocumento
      );
      if (response.status == 200) {
        const blob = new Blob([response.data], {
          type: "application/pdf",
        });

        const file = new File([blob], "documento.pdf", {
          type: "application/pdf",
        });

        // Descargar el archivo
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(file);
        link.download = nombreDocumento.toString();
        link.click();
      }
    },

    //file input change
    inputFileChange() {
      let currentFiles = this.archivoEspecificaciones;
      let n_files = this.files;

      currentFiles = currentFiles != null ? currentFiles : [];
      n_files = currentFiles != null && currentFiles.length > 0 ? n_files : [];

      n_files = [...currentFiles, ...n_files];

      this.files = n_files;
    },

    async agregarInsumo() {
      if (!this.$v.$invalid && !this.checkValidations()) {
        /*
        logica para guardar insumo del tipo 1,
        difiere de los otros dos por el tipo de peticion,
        necesita enviar n numero de binarios(documentos),
        por lo cual es necesario un form data
        */
        if (this.$route.params.tipo == 1) {
          if (this.validateData()) {
            if (this.totalDetailInsumo > this.nuevoInsumo.monto_disponible) {
              this.temporalAlert({
                show: true,
                message: "El valor total sobrepasa el monto disponible",
                type: "error",
              });
            } else {
              let formData = this.gatherFormData();
              const response =
                await this.services.SolicitudCompra.addSolicitudInsumo(
                  this.id_solicitud,
                  formData
                );
              if (response) {
                if (response.status == 201) {
                  if (this.files.length > 0) {
                    let data = new FormData();

                    for (let iterador of this.files) {
                      data.append("archivo_especificacion", iterador);
                    }

                    const responseArchivo =
                      await this.services.SolicitudCompra.putArchivoEspecificaciones(
                        response.data.id_solicitud_compra,
                        data
                      );

                    if (responseArchivo.status == 200) {
                      this.temporalAlert({
                        show: true,
                        message: "Insumo agregado con éxito",
                        type: "success",
                      });
                      this.$router.push({
                        name: "compras",
                        params: {
                          tipo: Number(this.$route.params.tipo),
                          id_solicitud: this.id_solicitud,
                        },
                      });
                    }
                  } else {
                    this.temporalAlert({
                      show: true,
                      message: "Insumo agregado con éxito",
                      type: "success",
                    });
                    this.$router.push({
                      name: "compras",
                      params: {
                        tipo: Number(this.$route.params.tipo),
                        id_solicitud: this.id_solicitud,
                      },
                    });
                  }
                }
              }
            }
          } else {
            return;
          }
        } else {
          this.nuevoInsumo.id_solicitud_compra = this.id_solicitud;
          this.nuevoInsumo.solicitud_compra_detalle_obs = [
            {
              id_mes: 13,
              cantidad: Number(this.cantidad),
              aprobacion_ufi: false,
            },
          ];
          const res = await this.guardarInsumo(this.id_solicitud);

          if (res === 201) {
            this.temporalAlert({
              show: true,
              message: "Insumo agregado con éxito",
              type: "success",
            });
            this.$router.push({
              name: "compras",
              params: {
                tipo: Number(this.$route.params.tipo),
                id_solicitud: this.id_solicitud,
              },
            });
          }
        }
      } else {
        this.returnError();
      }
    },

    async editarInsumo() {
      if (!this.$v.$invalid && !this.checkValidations()) {
        const data = {};

        Object.keys(this.nuevoInsumo).forEach((key) => {
          if (this.nuevoInsumo[key] !== null && key !== "cotizaciones") {
            data[key] = this.nuevoInsumo[key];
          }
        });

        if (this.$route.params.tipo == 1) {
          if (!this.validateData()) {
            return;
          }
        }

        const { status } =
          await this.services.SolicitudCompra.updateSolicitudCompraInsumo(
            this.nuevoInsumo.id,
            data
          );

        if (status == 200) {
          if (this.files.length > 0) {
            let data = new FormData();
            for (let iterador of this.files) {
              data.append("archivo_especificacion", iterador);
            }
            const responseArchivo =
              await this.services.SolicitudCompra.putArchivoEspecificaciones(
                this.idInsumoEditando,
                data
              );

            if (responseArchivo.status == 200) {
              this.temporalAlert({
                show: true,
                message: "Insumo cargado y actualizado con éxito",
                type: "success",
              });
              this.$router.push({
                name: "compras",
                params: {
                  tipo: Number(this.$route.params.tipo),
                  id_solicitud: this.id_solicitud,
                },
              });
            }
          } else {
            this.temporalAlert({
              show: true,
              message: "Insumo actualizado con éxito",
              type: "success",
            });
            this.$router.push({
              name: "compras",
              params: {
                tipo: Number(this.$route.params.tipo),
                id_solicitud: this.id_solicitud,
              },
            });
          }
        }
      } else {
        this.returnError();
      }
    },
    regresar() {
      if (this.handleUACI) {
        this.$router.push({
          name: "verificar-proveedor",
          params: { tipo: Number(this.$route.params.tipo) },
          query: { proveedor: this.$route.query?.proveedor },
        });
      } else {
        this.$router.back();
      }
    },
    abrirEliminarInsumo(item) {
      this.idMes = item.id_mes;
      this.id_insumo = item.id;
      this.modalEliminarInsumo = true;
    },
    async reloadInsumo(only_table = false) {
      const { data } =
        await this.services.SolicitudCompra.getSolicitudesCompraInsumo(
          this.nuevoInsumo.id
        );
      if (only_table) {
        // en la funcion agregarInsumo se llama esta funcion por lo que se actualiza toda la informacion
        // por lo que sobre escribe anterior, se agrega esta condicion para que solo actualice la tabla
        // espero que no se rompa nada
        this.items = data.solicitud_compra_detalle_obs.map((col, index) => ({
          correlativo: index + 1,
          precio_unitario: data.precio_unitario,
          total: col.cantidad * data.precio_unitario,
          mes: col.mes,
          id_mes: col.id_mes,
          cantidad: col.cantidad,
          id: col.id,
        }));
      } else {
        this.setInsumoData(data);
        this.setDataTableData();
      }
    },
    setDataTableData() {
      if (this.$route.params.tipo == 1 || this.$route.params.tipo == 3) {
        this.items = this.nuevoInsumo.solicitud_compra_detalle_obs.map(
          (col, index) => ({
            correlativo: index + 1,
            precio_unitario: this.nuevoInsumo.precio_unitario,
            gastos_adicionales:
              this.nuevoInsumo.gastos_adicionales /
              this.nuevoInsumo.solicitud_compra_detalle_obs.length,
            total: col.cantidad * this.nuevoInsumo.precio_unitario,
            mes: col.mes,
            id_mes: col.id_mes,
            cantidad: col.cantidad,
            id: col.id,
          })
        );
      } else {
        this.items = this.nuevoInsumo.solicitud_compra_detalle_obs.map(
          (col, index) => ({
            correlativo: index + 1,
            precio_unitario: this.productoSeleccionado.precio_unitario,
            total: col.cantidad * this.productoSeleccionado.precio_unitario,
            mes: col.mes,
            id_mes: col.id_mes,
            cantidad: col.cantidad,
            id: col.id,
          })
        );
      }
    },
    precioUnitarioByType() {
      switch (Number(this.$route.params.tipo)) {
        case 1 || "1":
          return this.nuevoInsumo.precio_unitario;
        case 2 || "2":
          return this.productoSeleccionado.precio_unitario;
        case 3 || "3":
          return this.$refs.lineaForm.form.precio;
        default:
          return null;
      }
    },
    totalGastoAdicional() {
      return this.$refs.lineaForm?.form.gasto / this.meses.length;
    },
    totalByType() {
      switch (Number(this.$route.params.tipo)) {
        case 1 || "1":
          return this.nuevoInsumo.precio_unitario * this.cantidad;
        case 2 || "2":
          return this.productoSeleccionado.precio_unitario * this.cantidad;
        case 3 || "3":
          return (
            this.cantidad * this.$refs.lineaForm.form.precio +
            this.totalGastoAdicional()
          );
        default:
          return null;
      }
    },

    /**
     * @description : Cambiar el estado del insumo, 3 = Aprobado, 4 = Descartado, 2 = Observado
     */
    async changeStatusInsumo(accion) {
      let id_estado =
        accion == "aprobar"
          ? this.handleAprobarInsumo
          : accion == "descartar"
          ? this.handleDescartarInsumo
          : this.handleObservarInsumo;
      let body = {
        id_estado_solicitud: id_estado,
        observacion: this.motivoObservacion,
      };
      const { status } =
        await this.services.SolicitudCompra.putSolicitudesInsumosEstado(
          this.$route.params.idSolicitud,
          this.nuevoInsumo.id,
          body
        );

      if (status == 200) {
        if (this.handleUACI) {
          this.$router.push({
            name: "verificar-proveedor",
            params: { tipo: Number(this.$route.params.tipo) },
            query: { proveedor: this.$route.query?.proveedor },
          });
        } else {
          this.$router.push({
            name: "compras",
            params: {
              tipo: Number(this.$route.params.tipo),
              id_solicitud: this.id_solicitud,
            },
          });
        }
      }
    },
    setMotivo(value) {
      this.motivoObservacion = value;
    },
    async saveDocument(value) {
      let formData = new FormData();
      formData.append("nombre", value.nombreArchivo);
      formData.append("documento", value.archivo);
      const { status } =
        await this.services.SolicitudCompra.postSolicitudesInsumosDocs(
          this.nuevoInsumo.id,
          formData
        );
      if (status == 201) {
        this.getDocumentosInsumo();
        this.temporalAlert({
          show: true,
          message: "Documento agregado exitosamente",
          type: "success",
        });
      }
    },
    async getDocumentosInsumo() {
      const { status, data } =
        await this.services.SolicitudCompra.getSolicitudesInsumosDocs(
          this.nuevoInsumo.id
        );
      if (status == 200) {
        this.insumosDocs = data;
      }
    },
    fillMonths() {
      const meses = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];
      const date = new Date();
      const month = date.getMonth();
      const mesesDespues = meses.slice(month, meses.length);
      const mesesAntes = meses.slice(0, month);
      const mesesTotalesId = mesesDespues.map((mes, index) => {
        return {
          id: mesesAntes.length + index + 1,
          name: mes,
        };
      });
      this.valores = mesesTotalesId;
    },
    actualizarPrecioUnitarioInsumos(nuevoPrecio) {
      this.items = this.items.map((element) => {
        element.precio_unitario = nuevoPrecio;
        element.total =
          Number(element.cantidad) * nuevoPrecio + element.gastos_adicionales;
        return element;
      });
    },
    actualizarGastosAdicionalesInsumos(nuevoGasto) {
      this.items = this.items.map((gasto) => {
        gasto.gastos_adicionales = nuevoGasto / Number(gasto.cantidad);
        gasto.total =
          gasto.gastos_adicionales +
          Number(gasto.cantidad) * gasto.precio_unitario;
        return gasto;
      });
    },
    async getSolicitudCompraInsumo() {
      const { status, data } =
        await this.services.SolicitudCompra.getSolicitudesCompraInsumo(
          this.$route.params.idInsumo
        );
      if (status === 200) {
        if (data.especificaciones.length > 0) {
          this.flagEdicionArchivo = !this.flagEdicionArchivo;
          this.archivoEspecificacionesPrecargado = data.especificaciones;
        }
        this.idInsumoEditando = data.id;
        const response = await this.services.Obs.getObsDetail(
          data.detalle_obs.codigo
        );

        if (response.status === 200) {
          this.setObjetoPresupuestario(response.data);
        }

        this.setInsumoData(data);
        this.unidadMedida = data.unidad_medida;
        this.precio_unitario = data.precio_unitario;
        this.cantidad =
          this.nuevoInsumo.solicitud_compra_detalle_obs[0].cantidad;
        // this.setEstadoInsumo(data.estado);

        if (this.$route.params.tipo == 2) {
          await this.obtenerProducto(data.id_presentacion);
        }
      }
    },
  },
  computed: {
    ...mapState("catalogo", [
      "productoSeleccionado",
      "id_producto_seleccionado",
    ]),
    ...mapState("agregarInsumo", [
      "insumoSeleccionado",
      "id_solicitud",
      "nuevoInsumo",
      "insumoEditable",
      "ctl_unidades_medida",
      "objetoPresupuestario",
      "estadoInsumo",
      "idEstadoInsumo",
    ]),
    ...mapState("comprasModule", ["pasoSolicitud", "pasosSolicitudes"]),
    ...mapState(["selectedUnidad"]),

    archivoEspecificacionesError() {
      const errors = [];
      // if (this.archivoEspecificaciones?.length > 1) {
      //   errors.push("error");
      // }
      if (!this.validateFormatArchivosEspecificaciones()) {
        errors.push("Error en el formato de los archivos");
      }
      // if (!this.validateSizeArchivosEspecificaciones()) {
      //   errors.push("Error en el tamaño de los archivos");
      // }
      return errors;
    },

    showActionsInsumo() {
      if (this.pasoSolicitud) {
        return (
          this.handleCargo && this.handleEstado && this.pasoSolicitud?.id !== 4
        );
      } else {
        this.$router.push({
          name: "list-solicitudes-compra",
        });
      }
    },
    totalDetailInsumo() {
      return this.items.reduce((acc, item) => acc + item.total, 0);
    },

    filterMonths() {
      if (this.items.length == 0) {
        return this.valores;
      } else {
        return this.valores.filter((col) => {
          return !this.items.some((item) => item.id_mes == col.id);
        });
      }
    },
    verifyEdit() {
      // TODO: agregar logica para otros tipos
      if (this.$route.params.tipo == 1 || this.$route.params.tipo == 3) {
        return this.insumoEditable;
      } else if (this.$route.params.tipo == 2) {
        return true;
      }
    },

    handleEstado() {
      const idEstados = [1, 2, 5, 8, 3];

      return (
        (idEstados.includes(this.idEstadoInsumo) &&
          this.selectedUnidad.cargo[0]?.id !== 6) ||
        ([1].includes(this.idEstadoInsumo) &&
          this.selectedUnidad.cargo[0]?.id === 6) ||
        this.idEstadoInsumo == null
      );
    },
    handleCargo() {
      return (
        (this.selectedUnidad.cargo[0]?.id == 6 &&
          this.estadoInsumo !== "Con observación") ||
        this.selectedUnidad.cargo[0]?.id == 11 ||
        (this.selectedUnidad.cargo[0]?.id == 4 && this.idEstadoInsumo === 4)
      );
    },
    handleReadOnlyUACI() {
      return this.selectedUnidad.cargo[0]?.id !== 11;
    },
    handleUACI() {
      return (
        this.selectedUnidad.cargo[0]?.id == 11 && this.$route.params.tipo === 3
      );
    },
    handlePasosSolicitudes() {
      if (this.selectedUnidad.cargo[0]?.id === 6) {
        return this.pasoSolicitud?.id === this.pasosSolicitudes.elaborada;
      } else {
        return (
          (this.pasoSolicitud == null ||
            this.pasoSolicitud?.id === this.pasosSolicitudes.borrador ||
            this.pasoSolicitud?.id === this.pasosSolicitudes.observacion ||
            this.pasoSolicitud?.id === this.pasosSolicitudes.observadoUFI ||
            this.pasoSolicitud?.id === this.pasosSolicitudes.observadoUACI) &&
          (this.selectedUnidad.cargo[0]?.id === 11 ||
            this.selectedUnidad.cargo[0]?.id === 9 ||
            this.selectedUnidad.cargo[0]?.id === 3 ||
            this.selectedUnidad.cargo[0]?.id === 5)
        );
      }
    },

    handleReadOnlyHeaders() {
      if (this.handleUACI || !this.handlePasosSolicitudes) {
        return this.headersReadOnly;
      } else return this.headers;
    },
    validacionUACI() {
      return this.handleUACI && this.insumosDocs.length == 0;
    },
    textAreaErrorMsg() {
      return this.$route.params.tipo == 1
        ? "Debe de escribir una especificación"
        : "Se debe de escribir una aclaración u observación";
    },
    labelObservaciones() {
      return this.$route.params.tipo == 1
        ? "Especificaciones *"
        : "Aclaraciones y observaciones *";
    },
    detailsErrors() {
      const errors = [];
      if (!this.$v.items.$dirty) return errors;
      !this.$v.items.required &&
        errors.push("Debe agregar al menos un detalle para este insumo");
      return errors;
    },
    precioErrors() {
      const errors = [];
      if (!this.$v.precio_unitario.$dirty) return errors;
      !this.$v.precio_unitario.required &&
        errors.push("Debe escribir el precio");
      !this.$v.precio_unitario.precio &&
        errors.push("Precio debe ser mayor a 0");
      !this.$v.precio_unitario.decimal &&
        errors.push("El campo precio sólo acepta enteros y decimales");
      !this.$v.precio_unitario.priceDecimals &&
        errors.push(
          "No se permiten más de 2 dígitos después del punto decimal"
        );
      return errors;
    },
    aclarionesErrors() {
      const errors = [];
      if (!this.$v.aclaraciones_observaciones.$dirty) return errors;
      !this.$v.aclaraciones_observaciones.required &&
        errors.push(this.textAreaErrorMsg);
      !this.$v.aclaraciones_observaciones.maxLength &&
        errors.push("Máximo de caracteres 250");
      return errors;
    },
    cantidadErrors() {
      const errors = [];
      if (!this.$v.cantidad.$dirty) return errors;
      !this.$v.cantidad.required &&
        errors.push("Debe de escribir la cantidad a solicitar");
      !this.$v.cantidad.numeric && errors.push("Debe escribir solo números");
      return errors;
    },
    unidadErrors() {
      const errors = [];
      if (!this.$v.unidad_medida.$dirty) return errors;
      !this.$v.unidad_medida.required &&
        errors.push("Seleccione una unidad de medida");
      return errors;
    },
    sortedMonths() {
      const meses = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];
      return this.items.sort((a, b) => {
        return meses.indexOf(a.mes) - meses.indexOf(b.mes);
      });
    },
    handleAprobarInsumo() {
      switch (this.selectedUnidad.cargo[0]?.nombre) {
        case "JEFE UNIDAD":
          return 3;
        case "JEFE UFI":
          return 6;
        case "JEFE UCP":
          return 9;
      }
    },
    handleDescartarInsumo() {
      switch (this.selectedUnidad.cargo[0]?.nombre) {
        case "JEFE UNIDAD":
          return 4;
        case "JEFE UFI":
          return 7;
        case "JEFE UCP":
          return 10;
      }
    },
    handleObservarInsumo() {
      switch (this.selectedUnidad.cargo[0]?.nombre) {
        case "JEFE UNIDAD":
          return 2;
        case "JEFE UFI":
          return 5;
        case "JEFE UCP":
          return 8;
      }
    },
  },
  watch: {
    precio_unitario(value) {
      value
        ? this.setPrecioUnitarioGlobal(Number(value))
        : this.setPrecioUnitarioGlobal(null);
    },
    unidadMedida(value) {
      value ? this.setIdUnidadMedida(value) : this.setIdUnidadMedida(null);
    },
    "nuevoInsumo.precio_unitario"() {
      if (this.$route.params.tipo == 1) {
        this.items.forEach((item) => {
          item.precio_unitario = this.nuevoInsumo.precio_unitario;
          item.total = item.cantidad * this.nuevoInsumo.precio_unitario;
        });
      }
    },
    aclaraciones_observaciones(value) {
      if (value) {
        this.setNuevoInsumoStep3(value);
      } else {
        this.setNuevoInsumoStep3(null);
      }
    },
    cantidad(value) {
      if (value) {
        this.setCantidadStep3(value);
      } else {
        this.setCantidadStep3(null);
      }
    },
    modalInsumo(value) {
      if (!value) {
        this.cantidad = null;
        this.updateCorrelativo();
      }
    },
  },
  async created() {
    window.scrollTo(0, 0);
    if (this.$route.params.idInsumo) {
      this.setInsumoEditable(true);
      await this.getSolicitudCompraInsumo();
      this.setDataTableData();
      this.aclaraciones_observaciones = this.nuevoInsumo.observacion;
    }
    this.fillMonths();
    this.setIdSolicitud(this.$route.params.idSolicitud);

    if (this.handleUACI) {
      this.getDocumentosInsumo();
    }

    if (this.$route.params.tipo == 2) {
      const response = await this.services.Obs.getObsDetail(
        this.productoSeleccionado.codigo
      );

      // Asignamos el precio unitario del producto seleccionado
      this.precio_unitario = this.productoSeleccionado.precio_unitario;

      if (response.status === 200) {
        this.setObjetoPresupuestario(response.data);
      }
    }

    // if (this.$route.params.tipo == 3) {
    //   if (this.id_solicitud == null) {
    //     this.$router.push({
    //       name: "compras",
    //       params: {
    //         tipo: Number(this.$route.params.tipo),
    //         id_solicitud: this.id_solicitud,
    //       },
    //     });
    //   }
    // }
  },
  beforeDestroy() {
    this.setInsumoEditable(false);
    this.clearFiltrosEstructuras();
    this.setNuevoInsumoStep3(null);
    this.cambiarProductoSeleccionado({});
  },
};
</script>
<style scoped lang="scss">
:deep(.v-input--selection-controls__input) {
  color: #f4f7fd;
}

:deep(.v-input--selection-controls__input:hover) {
  color: #f4f7fd;
}

.caracteristica-producto-titulo,
.caracteristica-producto-subtitulo {
  padding: 0px 12px 0px 12px;
  margin: 0px;

  p {
    margin: 0px 0px 5px 0px;
  }
}

.caracteristica-producto-titulo p {
  color: #697185;
}

.modal {
  overflow: hidden;
}
</style>
