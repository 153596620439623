<template>
  <section>
    <v-col cols="12" class="d-flex flex-column my-6">
      <span class="secondary--text text-h6">Agregar Insumo</span>
    </v-col>
    <v-row>
      <v-col cols="6">
        <ObsPreviewComponent :obs="productoSeleccionado" :title="false" />
      </v-col>
      <v-col cols="3" class="mx-4">
        <div class="d-flex flex-column mt-4">
          <p>Valoraciones:</p>
          <v-rating
            readonly
            :value="ratingProvider"
            half-increments
            background-color="secondary"
            color="secondary"
            size="38"
          />
        </div>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import { mapState } from "vuex";
import ObsPreviewComponent from "@/components/ObsPreviewComponent.vue";

export default {
  name: "AgregarInsumoMarcoComponent",
  components: {
    ObsPreviewComponent,
  },
  data: () => ({
    ratingProvider: 0,
  }),
  computed: {
    ...mapState("catalogo", ["productoSeleccionado"]),
  },
  watch:{
    productoSeleccionado(){
      this.fetchCalificacion();
    }
  },
  methods: {
    async fetchCalificacion() {
      const idProveedor =
        this.productoSeleccionado?.obs_proveedor?.id_proveedor;
      const { data } = await this.services.Proveedores.getCalificacionProveedor(
        idProveedor
      );
      if (data) {
        this.ratingProvider = Number.parseFloat(data?.calificacion);
      }
    },
  },
  created() {
    if (this.productoSeleccionado?.obs_proveedor?.id_proveedor) this.fetchCalificacion();
  },
};
</script>
