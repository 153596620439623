<template>
  <div>
    <v-col cols="12" class="d-flex flex-column my-6">
      <span class="secondary--text text-h6">Agregar Insumo</span>
      <span>{{ objetoPresupuestario?.codigo }} {{ objetoPresupuestario?.nombre }} </span>
        <span
          v-if="objetoPresupuestario?.nombre_obs_clase"
          v-text="'Clase: ' + objetoPresupuestario?.nombre_obs_clase"
        />
        <span
          v-if="objetoPresupuestario?.nombre_obs_familia"
          v-text="'Familia: ' + objetoPresupuestario?.nombre_obs_familia"
        />
        <span
          v-if="objetoPresupuestario?.nombre_obs_segmento"
          v-text="'Segmento: ' + objetoPresupuestario?.nombre_obs_segmento"
        />
    </v-col>
    <v-row class="pt-2">
      <v-col cols="12" xl="10" lg="10" md="12" sm="12" xs="12">
        <v-text-field
          label="Nombre del artículo *"
          outlined
          v-model="form.articulo"
          :disabled="editArticulo"
          :error-messages="articuloErrors"
          @blur="$v.form.articulo.$touch()"
          @keyup="setData('nombre_origen_compra_en_linea', form.articulo)"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xl="10" lg="10" md="12" sm="12" xs="12">
        <v-text-field
          label="Enlace del artículo *"
          outlined
          v-model="form.enlace"
          :error-messages="enlaceErrors"
          @blur="$v.form.enlace.$touch()"
          @keyup="setData('url_origen_compra_en_linea', form.enlace)"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xl="6" lg="6" md="10" sm="12" xs="12" class="pt-0">
        <autocomplete-proveedores
          ref="autoProvider"
          v-model="proveedor"
          :error-messages="proveedorErrors"
          @blur="$v.proveedor.$touch()"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { required, helpers } from "vuelidate/lib/validators";
import { mapActions, mapMutations, mapState } from "vuex";
import AutocompleteProveedores from "./AutocompleteProveedoresComponent.vue";
import MoneyTextFieldComponent from "../../../components/MoneyTextFieldComponent.vue";

// Validación personalizada para sitios web
const url = helpers.regex(
  "url",
  /^(https:\/\/www\.|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,8}(:[0-9]{1,8})?(\/.*)?$/
);
const initHttps = helpers.regex("initHttps", /^(http?:\/\/|https?:\/\/)/);

// Función para validar que el precio se mayor que 0
const precio = (value) => {
  return value > 0;
};

export default {
  name: "AgregarInsumoCompraLineaComponent",
  components: {
    AutocompleteProveedores,
    MoneyTextFieldComponent,
  },
  props: {
    obs: {
      type: Object,
    },
    action: {
      type: String,
    },
    unidadMedida: {
      type: Object | String,
    },
    precioUnitario: {
      type: String | Number,
    }
  },
  validations: {
    form: {
      articulo: {
        required,
      },
      enlace: {
        required,
        url,
        initHttps,
      },
    },
    proveedor: {
      required,
    },
  },
  data: () => ({
    editArticulo: false,
    form: {
      articulo: null,
      enlace: null,
      unidad: null,
      precio: null,
      observaciones: null,
    },
    proveedor: {},
    modalAgregar: false,
    modalEliminar: false,
    detalles: [],
  }),
  computed: {
    ...mapState("agregarInsumo", [
      "nuevoInsumo",
      "unidades_medida",
      "objetoPresupuestario",
      "ctl_unidades_medida",
      "proveedores_web",
      "insumoEditable",
    ]),
    articuloErrors() {
      const errors = [];
      if (!this.$v.form.articulo.$dirty) return errors;
      !this.$v.form.articulo.required &&
        errors.push("Nombre del artículo es obligatorio");
      return errors;
    },
    enlaceErrors() {
      const errors = [];
      if (!this.$v.form.enlace.$dirty) return errors;
      !this.$v.form.enlace.required &&
        errors.push("Enlace del artículo es obligatorio");
      !this.$v.form.enlace.url && errors.push("El sitio web es inválido");
      !this.$v.form.enlace.initHttps &&
        errors.push("El sitio web debe iniciar con (http://) o (https://)");
      return errors;
    },
    proveedorErrors() {
      const errors = [];
      if (!this.$v.proveedor.$dirty) return errors;
      !this.$v.proveedor.required && errors.push("Proveedor es obligatorio");
      return errors;
    },
  },
  watch: {
    proveedor() {
      this.setData("proveedor", this.proveedor);
    },
    "nuevoInsumo.id"() {
      this.form.articulo = this.nuevoInsumo.nombre_origen_compra_en_linea;
      this.form.enlace = this.nuevoInsumo.url_origen_compra_en_linea;
      this.form.proveedor = this.nuevoInsumo.proveedor_web_nombre;
      this.form.unidad = this.nuevoInsumo.id_unidad_medida;
      this.form.precio = this.nuevoInsumo.precio_unitario;
      const provider = {
        id: this.nuevoInsumo.proveedor_web_id,
        nombre: this.nuevoInsumo.proveedor_web_nombre,
      };
      this.proveedor = provider;
      const entries = [provider];
      this.$refs.autoProvider.initData(provider, entries);
    },
  },
  methods: {
    ...mapActions("agregarInsumo", ["getUnidadesMedida", "getProveedoresWeb"]),
    ...mapMutations("agregarInsumo", ["setNuevoInsumoEdit"]),
    inyectInfoUnidad(unidad) {
      if (unidad) {
        this.setData("id_unidad_medida", unidad?.id);
        this.setData("unidad_medida", unidad?.nombre);
      }
    },
    setData(atribute, value) {
      if (atribute === "proveedor" && value !== null) {
        let nombre_proveedor = {
          atributo: "proveedor_web_nombre",
          valor: value.nombre,
        };
        let id_proveedor = {
          atributo: "proveedor_web_id",
          valor: value.id,
        };
        this.setNuevoInsumoEdit(nombre_proveedor);
        this.setNuevoInsumoEdit(id_proveedor);
      } else if (atribute === "precio_unitario") {
        let params = {
          atributo: atribute,
          valor: Number(value),
        };
        this.setNuevoInsumoEdit(params);
        this.$emit("cambioPrecioUnitario", value);
      } else if (atribute === "gastos_adicionales") {
        let params = {
          atributo: atribute,
          valor: Number(value),
        };
        this.setNuevoInsumoEdit(params);
        this.$emit("cambioGastos_adicionales", value);
      } else {
        let params = {
          atributo: atribute,
          valor: value,
        };
        this.setNuevoInsumoEdit(params);
      }
    },
    getRole() {
      if (this.haveRole("ROLE_UACI") || this.haveRole("ROLE_UACI_TECNICO")) {
        return (this.editArticulo = true);
      } else return (this.editArticulo = false);
    },
  },
  async created() {
    this.getRole();
    this.getUnidadesMedida();
    this.getProveedoresWeb();
    if (this.insumoEditable && this.nuevoInsumo.id) {
      this.form.articulo = this.nuevoInsumo.nombre_origen_compra_en_linea;
      this.form.enlace = this.nuevoInsumo.url_origen_compra_en_linea;
      this.form.proveedor = this.nuevoInsumo.proveedor_web_nombre;
      this.form.unidad = this.nuevoInsumo.id_unidad_medida;
      this.form.precio = this.nuevoInsumo.precio_unitario;
      this.form.gasto = this.nuevoInsumo.gastos_adicionales;
      this.proveedor.id = this.nuevoInsumo.proveedor_web_id;
      this.proveedor.nombre = this.nuevoInsumo.proveedor_web_nombre;
    }
  },
  beforeDestroy() {
    this.formarticulo = null;
    this.form.enlace = null;
    this.form.proveedor = {};
    this.form.unidad = null;
    this.form.precio = null;
    this.form.observaciones = null;
  },
};
</script>
