<template>
  <section class="px-3 px-md-0 ml-md-10">
    <v-row>
      <p
        v-if="
          pasoSolicitud?.id !== pasosSolicitudes.borrador &&
          handleAprobarSolicitudRole
        "
        class="text-h6 text--secondary mb-0"
      >
        {{ correlativoSolicitud }}
      </p>
    </v-row>
    <titulo />
    <v-row
      v-if="
        !handleAprobarSolicitudStatus &&
        pasoSolicitud?.id !== pasosSolicitudes.borrador &&
        pasoSolicitud?.id !== pasosSolicitudes.observacion &&
        handleAprobarSolicitudRole
      "
    >
      <v-col cols="12" class="px-0 d-flex inline">
        <div v-show="!solicitudObservada">
          <v-btn
            :disabled="habilitarAprobacion"
            @click="aprobarDialog = true"
            class="px-0"
            text
            color="green"
          >
            <v-icon color="green" small class="mr-2">mdi-send</v-icon>
            Aprobar solicitud
          </v-btn>
        </div>

        <v-btn
          :disabled="habilitarCancelacion"
          @click="cancelarDialog = true"
          class="px-0 mx-5"
          text
          color="red"
        >
          <v-icon small class="mr-2" color="red">mdi-cancel</v-icon>
          Cancelar solicitud
        </v-btn>
        <v-btn
          :disabled="habilitarObservacionUFI"
          @click="observarDialog = true"
          text
          color="primary"
          class="px-0"
        >
          <v-icon color="primary-text" small class="mr-2"
            >mdi-message-reply-text</v-icon
          >
          Marcar con observación
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-tabs v-model="tabsMenu" background-color="bgMinsal" show-arrows>
        <v-tabs-slider color="secondary"></v-tabs-slider>
        <v-tab
          v-if="handleTabsRoleStatus"
          href="#tab-1"
          class="secondary--text v-step-0"
          ><v-icon>mdi-package-variant-closed </v-icon>Insumos</v-tab
        >
        <v-tab
          v-if="$route.params.tipo != 1"
          href="#tab-2"
          class="secondary--text v-step-1"
          ><v-icon>mdi-account-multiple</v-icon>Proveedores</v-tab
        >
        <!-- esta validacion poseia al jefe de unidad: handleTabsRoleStatus && isJefeUnidad && $route.params.tipo == 1 -->
        <v-tab
          v-if="handleTabsRoleStatus && $route.params.tipo == 1"
          href="#tab-3"
          class="secondary--text"
          ><v-icon>mdi-account-multiple</v-icon>Administradores de
          contrato</v-tab
        >
        <v-tab
          v-if="handleTabsRoleStatus && $route.params.tipo == 1"
          href="#tab-4"
          class="secondary--text"
          ><v-icon>mdi-cash</v-icon>Precios de mercado</v-tab
        >
      </v-tabs>
    </v-row>
    <v-row>
      <v-tabs-items style="width: 100%" v-model="tabsMenu" class="v-step-2">
        <v-tab-item value="tab-1">
          <v-card color="bgMinsal">
            <insumos
              :headers="setInsumosHeader"
              @updateListProv="getProvidersList"
              class="py-7 px-3"
            />
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-2" class="tabStyle">
          <v-card color="bgMinsal">
            <proveedores
              :prevRoute="prevRoute"
              :headers="setProveedoresHeader"
            />
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-3" class="tabStyle">
          <v-card color="bgMinsal">
            <AdministradorContratoComponent />
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-4" class="tabStyle">
          <v-card color="bgMinsal">
            <PreciosMercadoComponent />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-row>
    <dialogoConfirmacion
      :show="aprobarDialog"
      title="¿Desea aprobar la solicitud?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Continuar"
      @close="aprobarDialog = false"
      @confirm="changeSolicitudStatus('aprobar')"
    />
    <dialogoConfirmacion
      :show="cancelarDialog"
      title="¿Desea cancelar la solicitud?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Continuar"
      @close="cancelarDialog = false"
      @confirm="changeSolicitudStatus('cancelar')"
    />
    <dialogoConfirmacion
      :show="observarDialog"
      title="Marcar con observación"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Continuar"
      showMotivoCancelar
      @input="setMotivo"
      @close="observarDialog = false"
      @confirm="changeSolicitudStatus('observar')"
    />
    <ChatComponent :idSolicitud="id_solicitud" modelo="SolicitudCompra" />
  </section>
</template>
<script>
import titulo from "./components/TituloVistaComponent.vue";
import insumos from "../../components/InsumoComponent.vue";
import proveedores from "../../components/ProveedorComponent.vue";
import ChatComponent from "@/components/ChatComponent";
import dialogoConfirmacion from "@/components/ConfirmationDialogComponent.vue";
import AdministradorContratoComponent from "../../components/AdministradorContratoComponent.vue";
import PreciosMercadoComponent from "./components/PreciosMercadoComponent.vue";
import { mapState, mapMutations } from "vuex";

export default {
  name: "procesoDeCompraView",
  components: {
    titulo,
    insumos,
    proveedores,
    ChatComponent,
    dialogoConfirmacion,
    AdministradorContratoComponent,
    PreciosMercadoComponent,
  },
  data: () => ({
    idURL: null,
    tabsMenu: null,
    prevRoute: null,
    currentStep: 0,
    solicitudObservada: false,
    guiaCompletada: false,
    encabezadoProcesoCompra: [
      {
        text: "No",
        value: "correlativo",
        align: "center",
        value: "correlativo",
        sortable: false,
      },
      {
        text: "OBS",
        align: "center",
        value: "obs",
      },
      {
        text: "Especificación",
        align: "center",
        value: "observacion",
      },
      {
        text: "Unidad de medida",
        align: "center",
        value: "unidad_medida",
      },
      {
        text: "Cantidad solicitada",
        align: "center",
        value: "cantidad",
      },
      {
        text: "Total ($)",
        align: "center",
        value: "total",
      },
      // {
      //   text: "Precios del mercado ($)",
      //   align: "center",
      //   value: "precio_unitario",
      // },
      {
        text: "Precio unitario ($)",
        align: "center",
        value: "precio_unitario",
      },
      {
        text: "Estado",
        align: "center",
        width: 150,
        value: "estado",
      },
      {
        text: "Acciones",
        value: "accion",
        align: "center",
        value: "accion",
      },
    ],
    aprobarDialog: false,
    cancelarDialog: false,
    observarDialog: false,
    motivo: "",
    encabezadoConvenioMarco: [
      {
        text: "Correlativo",
        align: "center",
        value: "correlativo",
        sortable: false,
      },
      {
        text: "Presentación",
        value: "presentacion",
      },
      {
        text: "Especificación",
        align: "center",
        value: "detalle",
      },
      {
        text: "Cantidad",
        align: "center",
        value: "cantidad",
      },

      { text: "Total ($)", align: "center", value: "total" },
      {
        text: "Estado",
        value: "estado",
        width: 150,
      },
      {
        text: "Acciones",
        sortable: false,
        align: "center",
        width: "10%",
        value: "accion",
      },
    ],
    encabezadosCompraLinea: [
      {
        text: "Correlativo",
        align: "center",
        value: "correlativo",
        sortable: false,
      },
      {
        text: "Nombre del producto",
        value: "nombre_origen_compra_en_linea",
      },
      {
        text: "Unidad de medida",
        align: "center",
        value: "unidad_medida",
      },
      {
        text: "Precio unitario ($)",
        align: "center",
        value: "precio_unitario",
      },
      {
        text: "Cantidad",
        align: "center",
        value: "cantidad",
      },
      { text: "Total ($)", align: "center", value: "total" },
      {
        text: "Cantidad aprobada",
        align: "center",
        value: "cantidad_aprobar",
      },
      {
        text: "Total aprobado ($)",
        align: "center",
        value: "total_aprobar",
      },
      {
        text: "Enlace de compra",
        align: "center",
        value: "url_origen_compra_en_linea",
      },
      {
        text: "Estado",
        align: "center",
        value: "estado",
      },
      {
        text: "Acciones",
        sortable: false,
        align: "center",
        width: "10%",
        value: "accion",
      },
    ],
    encabezadosProveedorMarco: [
      {
        text: "Correlativo",
        align: "center",
        value: "correlativo",
        sortable: false,
      },
      {
        text: "Proveedor",
        value: "nombre",
      },
    ],
    encabezadosProveedorWeb: [
      {
        text: "Correlativo",
        align: "center",
        value: "correlativo",
        sortable: false,
      },
      {
        text: "Proveedor",
        value: "nombre",
      },
      {
        text: "Estado",
        value: "estado",
        width: 160,
      },
      {
        text: "Acciones",
        value: "acciones",
        sortable: false,
        align: "center",
      },
    ],
  }),
  computed: {
    ...mapState("agregarInsumo", [
      "id_solicitud",
      "insumosList",
      "proveedoresList",
      "getTour",
    ]),
    ...mapState("comprasModule", [
      "pasoSolicitud",
      "correlativoSolicitud",
      "pasosSolicitudes",
    ]),
    ...mapState(["userInfo"]),
    ...mapState("solicitudCompra", ["lista_administradores_de_contrato"]),
    ...mapState(["cargos", "selectedUnidad"]),
    cargoSeleccionado() {
      return this.selectedUnidad.cargo[0].id;
    },
    cargoSeleccionadoNombre() {
      return this.selectedUnidad.cargo[0].nombre;
    },

    handleAprobarSolicitudRole() {
      return (
        this.cargoSeleccionado == this.cargos.jefe_ufi ||
        this.cargoSeleccionado == this.cargos.tecnico_ufi ||
        this.cargoSeleccionado == this.cargos.jefe_uaci ||
        (this.cargoSeleccionado == this.cargos.jefe_unidad_solicitante &&
          this.pasoSolicitud?.id == this.pasosSolicitudes.elaborada)
      );
    },
    handleAprobarSolicitudStatus() {
      return this.cargoSeleccionadoNombre
        .toLowerCase()
        .includes(this.pasoSolicitud?.nombre.toLowerCase().split(" ").pop());
    },
    handleTabsRoleStatus() {
      switch (this.cargoSeleccionado) {
        case 11:
          return [
            this.pasosSolicitudes.aprobadoUACI,
            this.pasosSolicitudes.elaborada,
          ].includes(this.pasoSolicitud?.id);
        default:
          return true;
      }
    },
    setInsumosHeader() {
      const id = Number(this.$route.params.tipo);
      switch (id) {
        case 1 || "1":
          return this.encabezadoProcesoCompra;
        case 2 || "2":
          return this.encabezadoConvenioMarco;
        case 3 || "3":
          return this.encabezadosCompraLinea;
      }
    },
    setProveedoresHeader() {
      const id = Number(this.$route.params.tipo);
      switch (id) {
        case 2 || "2":
          return this.encabezadosProveedorMarco;
        case 3 || "3":
          return this.encabezadosProveedorWeb.filter(
            (target) => target.value !== "acciones"
          );
      }
    },
    habilitarCancelacion() {
      if (
        this.proveedoresList.length > 0 &&
        this.$route.params.tipo == 3 &&
        this.cargoSeleccionado == this.cargos.jefe_uaci
      ) {
        return (
          this.proveedoresList.filter(
            (target) => target.estado_proveedor_web?.nombre == "Pendiente"
          ).length > 0
        );
      } else {
        return (
          this.cargoSeleccionado == this.cargos.jefe_ufi &&
          this.insumosList.some((insumo) => insumo.id_estado == 5)
        );
      }
    },
    habilitarObservacionUFI() {
      return (
        this.cargoSeleccionado == this.cargos.jefe_ufi &&
        this.insumosList.some(
          (insumo) => insumo.id_estado == 5 || insumo.id_estado == 7
        )
      );
    },
    habilitarAprobacion() {
      // funcion para comprobar que dentro del array insumosList exista al menos un con id_estado = 2

      this.solicitudObservada = this.insumosList.some(
        (insumo) => insumo.id_estado == 2
      );

      if (
        this.cargoSeleccionado == this.cargos.jefe_uaci &&
        this.pasoSolicitud?.id !== 2
      ) {
        return (
          this.proveedoresList.filter(
            (target) =>
              target.estado_proveedor_web?.nombre == "Pendiente" ||
              target.estado_proveedor_web?.nombre == "Con observación"
          ).length > 0 ||
          this.proveedoresList.every(
            (target) => target.estado_proveedor_web?.nombre === "Descartado"
          )
        );
      } else if (
        (this.cargoSeleccionado == this.cargos.jefe_ufi ||
          this.cargoSeleccionado == this.cargos.tecnico_ufi) &&
        this.pasoSolicitud?.id !== 2
      ) {
        let permitirAprobacionUfi = true;
        this.insumosList.map((target) => {
          target.solicitud_compra_detalle_obs.map((item) => {
            if (item.aprobacion_ufi == true) permitirAprobacionUfi = false;
          });
        });

        return permitirAprobacionUfi;
      } else {
        let list = true;
        if (this.$route.params.tipo == 3 || this.$route.params.tipo == 2) {
          list = this.proveedoresList.length == 0;
        } else {
          list = this.lista_administradores_de_contrato.length == 0;
        }

        return (
          list ||
          this.insumosList.filter(
            (target) =>
              target.id_estado === 3 ||
              target.id_estado === 6 ||
              target.id_estado === 9 // Listo
          ).length === 0 ||
          this.insumosList.filter(
            (target) => target.id_estado === 1 // Pendiente
          ).length > 0 ||
          this.insumosList.length === 0
        );
      }
    },
    handlePasoSolicitudAprobar() {
      // validar paso de la solicitud para el perfil
      if (this.pasoSolicitud.id === 2) {
        switch (this.cargoSeleccionado) {
          case 4:
            return 6;
          case 6:
            return 3;
          case 11:
            return 3;
        }
      } else {
        switch (this.cargoSeleccionado) {
          case 4:
            return 5;
          case 5:
            return 5;
          case 6:
            return 3;
          case 11:
            return 6;
        }
      }
    },
    handlePasoSolicitudCancelar() {
      switch (this.cargoSeleccionadoNombre) {
        case "JEFE UNIDAD":
          return 9;
        case "JEFE UFI":
          return 10;
        case "JEFE UCP":
          return 11;
      }
    },
    handlePasoSolicitudObservar() {
      switch (this.cargoSeleccionadoNombre) {
        case "JEFE UNIDAD":
          return 8;
        case "JEFE UFI":
          return 13;
        case "JEFE UCP":
          return 12;
      }
    },
    isJefeUnidad() {
      return this.cargoSeleccionado == 4 || this.cargoSeleccionado == 9;
    },
  },
  methods: {
    ...mapMutations("agregarInsumo", ["setProveedoresList", "setIdSolicitud"]),
    ...mapMutations("comprasModule", ["setPasoSolicitud"]),
    setMotivo(value) {
      this.motivo = value;
    },
    async changeSolicitudStatus(accion) {
      // FIX: cambiar la forma de validar la accion, usar un id en vez de un string
      if (accion == "cancelar") {
        if (this.habilitarCancelacion) {
          this.temporalAlert({
            show: true,
            type: "error",
            message:
              "No se puede cancelar la solicitud, ya que hay proveedores pendientes de aprobación.",
          });
          return;
        }
      }

      let idPaso =
        this.pasoSolicitud.id == this.pasosSolicitudes.aprobadaUnidad &&
        this.cargoSeleccionado == this.cargos.jefe_uaci
          ? this.pasosSolicitudes.verificadaUACI
          : this.handlePasoSolicitudAprobar;

      if (
        this.cargoSeleccionado === this.cargos.jefe_ufi &&
        this.pasoSolicitud.id === 2 &&
        accion == "aprobar"
      )
        idPaso = 5;

      const { status } =
        await this.services.SolicitudCompra.updateSolicitudEstado(
          this.id_solicitud,
          {
            id_unidad: this.selectedUnidad.id,
            id_solicitud_paso:
              accion == "aprobar"
                ? idPaso
                : accion == "observar"
                ? this.handlePasoSolicitudObservar
                : this.handlePasoSolicitudCancelar,
            observacion: this.motivo,
            id_paso_actual: this.pasoSolicitud.id,
          }
        );

      if (status == 200 || status == 204) {
        this.$router.push("/proceso-solicitud-compra/list-solicitudes");
      }
    },
    async getProvidersList() {
      const { status, data } =
        await this.services.SolicitudCompra.getSolicitudesProveedoresInsumo(
          this.id_solicitud
        );

      if (status == 200) {
        this.setProveedoresList(data);
      }
    },
    async getSolicitud() {
      const { status, data } = await this.services.SolicitudCompra.getSolicitud(
        this.id_solicitud
      );

      if (status == 200) {
        const {
          solicitud: { paso },
        } = data;
        this.setPasoSolicitud(paso);
      }
    },
    nextStep() {
      this.currentStep = this.currentStep + 1;
      this.$shepherd.next(this.currentStep);
      this.dataTour(this.currentStep);
    },
    backStep() {
      this.currentStep = this.currentStep - 1;
      this.$shepherd.back(this.currentStep - 1);
      this.dataTour(this.currentStep);
    },
    cancelStep() {
      this.currentStep = null;
      this.$shepherd.cancel(this.currentStep);
      this.dataTour(this.currentStep);
    },
    completeTour() {
      this.currentStep = null;
      this.$shepherd.complete(this.currentStep);
      this.dataTour(this.currentStep);
      this.postTourCompletado();
    },
    dataTour(step) {
      const ruta = this.$router.currentRoute.path.split("/");
      const stepsInfo = {
        [ruta[1]]: {
          id: this.userInfo?.user?.id,
          email: this.userInfo?.user?.email,
          step: step,
        },
      };
      localStorage.setItem("StepInfo", JSON.stringify(stepsInfo));
    },
    getInfo() {
      const info = JSON.parse(localStorage.getItem("StepInfo"))
        ? JSON.parse(localStorage.getItem("StepInfo"))
        : null;
      const ruta = this.$router.currentRoute.path.split("/");
      if (info) {
        for (let value of Object.keys(info)) {
          if (ruta[1] == value) {
            if (this.userInfo?.user?.id == info[value].id) {
              this.currentStep = info[value].step;
              this.$shepherd.show(this.currentStep);
            }
          }
        }
      } else {
        this.$shepherd.show(0);
      }
    },
    stepsShepherd(dataStep4, descripcion) {
      this.$shepherd.addSteps([
        {
          id: "1",
          title: "Solicitud de compra en  bienes o servicios",
          text: "Apartado <strong>INSUMOS</strong> muestra una tabla con los insumos registrados",
          attachTo: {
            element: ".v-step-0",
            on: "bottom",
          },

          buttons: [
            {
              text: "Salir",
              action: this.cancelStep,
            },

            {
              text: "Siguiente",
              action: this.nextStep,
            },
            {
              text: "Omitir guía",
              action: this.cancelStep,
            },
          ],
          advanceOn: { selector: ".v-step-0", event: "click" },
        },
        {
          id: "2",
          title: "Solicitud de compra en  bienes o servicioss",
          text: "Tabla de insumos registrados, listado con la información más relevante",
          attachTo: {
            element: ".v-step-2",
            on: "bottom",
          },
          buttons: [
            {
              text: "Anterior",
              action: this.backStep,
            },

            {
              text: "Siguiente",
              action: this.nextStep,
            },
            {
              text: "Omitir guía",
              action: this.cancelStep,
            },
          ],
        },
        {
          id: "3",
          title: "Solicitud de compra en  bienes o servicios",
          text:
            "Apartado <strong>" +
            dataStep4 +
            "</strong> muestra una tabla con " +
            descripcion,
          attachTo: {
            element: ".v-step-1",
            on: "bottom",
          },
          buttons: [
            {
              text: "Anterior",
              action: this.backStep,
            },
            {
              text: "Completar guía",
              action: this.completeTour,
            },
          ],
        },
        {
          id: "4",
          title: "Solicitud de compra en  bienes o servicios",
          text:
            "Apartado <strong>" +
            dataStep4 +
            "</strong> muestra una tabla con " +
            descripcion,
          attachTo: {
            element: ".v-step-3",
            on: "bottom",
          },
          buttons: [
            {
              text: "Anterior",
              action: this.backStep,
            },
            {
              text: "Completar guía",
              action: this.completeTour,
            },
          ],
        },
      ]);
      this.getInfo();
    },
    async postTourCompletado() {
      const param = {
        id_flujo: 4,
        //completado: true,
      };
      const response = await this.services.TourService.postTourGuiado(param);
    },
    tourComplete() {
      if (this.getTour) {
        this.getTour.forEach((element) => {
          if (element.id == 4) {
            this.guiaCompletada = true;
          }
        });
      }
    },
  },
  async created() {
    let URLactual = window.location.href;
    let URLactual2 = URLactual.split("/");
    this.idURL = URLactual2[URLactual2.length - 1];
    this.setIdSolicitud(this.$route.params.id_solicitud);

    if (this.id_solicitud) {
      this.getProvidersList();
      await this.getSolicitud();

      if (
        ![
          this.cargos.tecnico_unidad,
          this.cargos.tecnico_uaci,
          this.cargos.tecnico_ufi,
        ].includes(this.cargoSeleccionado) &&
        this.pasoSolicitud?.id == 1
      ) {
        this.$router.push("/proceso-solicitud-compra/list-solicitudes");
      }
    }
  },
  mounted() {
    this.tourComplete();
    if (!this.guiaCompletada) {
      window.onpopstate = (event) => {
        this.$shepherd.steps.forEach((step) => {
          step.destroy();
          step.hide();
        });
      };
      setTimeout(() => {
        this.$nextTick(() => {
          let nombreCampo;
          let desc;
          if (this.idURL == "1") {
            nombreCampo = "PRECIOS DE MERCADO";
            desc = "las cotizaciones registradas";
          } else {
            nombreCampo = "PROVEEDORES";
            desc = "los proveedores registrados";
          }
          this.stepsShepherd(nombreCampo, desc);
        });
      }, 0);
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from.name;
    });
  },
};
</script>
<style>
.tabStyle {
  width: 100%;
}
.theme--light.v-application .text--secondary {
  color: #404a7a !important;
}
.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.4);
}
</style>
